import _ from "lodash";
import moment from "moment";
import template from "./hero.component.html";

// get geojson url via webpack
import GEO_JSON_URL from "./geo.geojson";

let $user;
let $router;
let $data;
let $adapter;
let $http;
let $q;
let $timeout;
let $interval;
let $env;
let $dashboard;
let $location;
let $notification;

let loadLocationListCache;

class controller {
  static get $inject() {
    return ["$injector"];
  }

  constructor($injector) {
    this.loading = true;

    this.chart = null;
    this.login = false;

    this.map = null;

    this.filter = "Deutschland";

    this.searchString = "";
    this.filterFunction = null;

    $q = $injector.get("$q");
    $http = $injector.get("$http");
    $timeout = $injector.get("$timeout");
    $interval = $injector.get("$interval");

    $router = $injector.get("opendash/services/router");
    $user = $injector.get("opendash/services/user");
    $data = $injector.get("opendash/services/data");
    $env = $injector.get("opendash/services/env");
    $dashboard = $injector.get("opendash/services/dashboard");
    $location = $injector.get("opendash/services/location");
    $notification = $injector.get("opendash/services/notification");

    this.availableLocations = [];
    this.locations = [];

    $location.wait().then(() => {
      this.locSelectionOptions = {
        locationServiceMode: true,
        search: true,
        multi: false,
        maxHeight: "160px",
        filter: location => this.filterLocation(location)
      };
    });

    $router.onChange(state => {
      this.map = null;
      this.init();
    });

    this.init();
  }
  get isMobile() {
    // return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return window.innerWidth < 1200;
  }

  get choice() {
    try {
      return $location.current[0].name;
    } catch (error) {
      return "-";
    }
  }

  showHero() {
    return $router &&
      $router.current &&
      $router.current.component === "od-dashboard"
      ? true
      : false;
  }

  async init() {
    // console.log("init");
    try {
      if (!this.showHero()) {
        return;
      }

      await $user.wait();
      await $data.wait();

      await $timeout(() => {}, 100);

      // render map only if not on mobile
      if (this.isMobile || this.map) return;

      // create leafmap
      this.mapInit();

      // set marker:
      //this.marker = new L.FeatureGroup();
      this.marker = [];
      this.placeMarker();

      //this.loading = false;
      // set control:
      //L.control.layers(L.tileLayer("")).addTo(this.map);
    } catch (error) {
      // console.warn(error);
    }

    await $q.resolve();
  }

  placeMarker() {
    if (this.map) {
      try {
        //Old Solution
        this.oms.clearMarkers();
        this.marker.forEach(item => {
          this.map.removeLayer(item);
        });

        //this.marker.clearLayers();
        /*
        this.marker.addLayer(
          new L.marker(
            L.latLng(
              $location.current[0].location.latitude,
              $location.current[0].location.longitude
            )
          )
        );
        this.map.addLayer(this.marker);
        */

        //New Solution
        var i = 0;
        $location.locations.forEach(e => {
          /*this.marker.addLayer(
            new L.marker(
              L.latLng(
                e.location.latitude,
                e.location.longitude
              ),{
                index: i
              }
            )
          )*/
          /*.on('click', this.markerOnClick);*/

          if (e && e.location) {
            var loc = new L.LatLng(e.location.latitude, e.location.longitude);
            var markers = new L.Marker(loc);
            this.marker.push(markers);
            markers.index = i;
            markers.parseid = e.id;

            this.map.addLayer(markers);

            markers
              .bindPopup("")
              .on("mouseover", marker => {
                // console.log("Mouse Over Marker");
                markers.openPopup();
                markers._popup.setContent(
                  $location.locations[marker.sourceTarget.index].name + ""
                );
              })
              .on("mouseout", marker => {
                markers.closePopup();
              });

            this.oms.addMarker(markers);
            i = i + 1;
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  markerOnClick(e) {
    // console.log(e);
    //alert("title of marker: " + e.sourceTarget.options.index);
  }

  setFilter(filter, filterFunction) {
    Object.keys(this.map._layers).forEach(key => {
      if (this.map._layers[key].setStyle) {
        this.map._layers[key].setStyle({
          color: "rgba(100,100,100,0.8)",
          weight: 0.7
        });
      }
    });

    $q.resolve().then(() => {
      if (filter) {
        this.filter = filter;
        this.filterFunction = filterFunction;
      } else {
        this.filter = "Deutschland";
        this.filterFunction = null;
      }
    });
  }

  filterLocation(location) {
    try {
      return this.filterFunction(location);
    } catch (error) {
      return true;
    }
  }

  async loadGeoJson() {
    let response = await fetch(GEO_JSON_URL);
    return await response.json();
  }

  async mapInit() {
    if (this.map) return;

    var tile_layer = L.tileLayer(
      "https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png",
      {}
    );

    this.map = L.map("ow__hero__map", {
      dragging: false,
      touchZoom: false,
      scrollWheelZoom: false,
      doubleClickZoom: false,
      boxZoom: false,
      tap: false,
      keyboard: false,
      zoomControl: false,
      attributionControl: false,
      prefix: false,
      zoomSnap: 0.1,
      minZoom: 5,
      maxZoom: 12,
      center: [51.4, 15],
      zoom: 5.25
      //layers: [L.tileLayer("http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png")]
      //layers: [L.tileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}")]
      //layers: [L.tileLayer("https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}{r}.png")]
    });

    tile_layer.addTo(this.map);
    tile_layer.on("load", e => {
      this.loading = false;
    });

    var lightIcon = L.Icon.Default;

    this.oms = new OverlappingMarkerSpiderfier(this.map, {
      keepSpiderfied: true
    });
    var popup = new L.Popup();
    this.oms.addListener("click", marker => {
      // console.log("Click on Marker OMS Listener");
      $location.setLocation([marker.parseid]);
    });
    this.oms.legColors.highlighted = "#439eca";

    this.map.on("click", e => {
      this.setFilter(null);
    });

    const geoJsonGermany = await this.loadGeoJson();

    const geo = L.geoJSON(geoJsonGermany, {
      clickable: false,
      style: {
        stroke: true,
        weight: 1.3,
        color: "rgba(100,100,100,0.9)",
        fill: true,
        //fillColor: "rgba(125, 141, 201, 0.3)", //purple
        fillColor: "rgba(41,116,179,0.2)", //blue
        //fillColor: "rgba(187,46,65,0.2)", //red
        fillOpacity: 1
      },
      onEachFeature: (feature, layer) => {
        layer.on({
          mouseout: function mouseout(e) {
            this.setStyle({
              //fillColor: "rgba(125, 141, 201, 0.3)" //purple
              fillColor: "rgba(41,116,179,0.2)" //blue
              //fillColor: "rgba(187,46,65,0.2)" //red
            });
          },
          mouseover: function mouseover(e) {
            this.setStyle({
              fillColor: "rgba(100,100,100,0.5)"
            });
          },
          click: e => {
            L.DomEvent.stopPropagation(e);

            const layer = e.target;
            const highlightColor = "black";
            const selected = e.target.options.color == highlightColor;

            if (selected) {
              this.setFilter(null);
              layer.setStyle({
                color: "rgba(100,100,100,0.8)",
                weight: 0.7
              });
            } else {
              let filter = e.target.feature.properties.GEN;
              this.setFilter(filter, location => location.province === filter);

              layer.bringToFront();
              layer.setStyle({
                color: highlightColor,
                weight: 2
              });
            }
          }
        });
      }
    });

    geo.addTo(this.map);
  }
}

let component = {
  template,
  controller
};

export default component;
