import parse from "./parse.config";

const backend = {
  protocol: "https://",
  base_url: "opendash.kompetenzzentrum-siegen.digital",
  wsprotocol: "wss://"
};

if (window.location.href.includes("localhost:4567")) {
  backend.protocol = "http://";
  backend.base_url = "localhost:4567";
  backend.wsprotocol = "ws://";
}

export default {
  parse,
  backend,
  frontpage: {
    qrcode: {
      url: backend.protocol + backend.base_url,
      userurl: parse.url,
      usercode: parse.applicationId
    },
    frontPageTiles: [
      {
        class: "opendash",
        icon: "fa fa-area-chart fa-3x",
        label: "Monitoring",
        action: "/dashboard"
      },
      {
        class: "openreport",
        icon: "fa fa-file-text-o fa-3x",
        label: "Reporting",
        action: "/reporting/list/"
      }, 
      {
        class: "openadmin",
        icon: "fa fa-cogs fa-3x",
        label: "Administration",
        action: "/market/",
        roles: ["Admin"]
      } 
    ]
  },
  showFrontpage: true,
  playground: false,
  reporting: {},
  locations: true
};
